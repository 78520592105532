/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

/*Blue #3c587a, Red #d42729;*/

/*Base*/

html {
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: Georgia, Times, Times New Roman, serif;
}

body {}


.form-control {border: 1px solid #3c587a; background-color: #fbf4b7;}


/*Remove spin buttons*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}



div.overlay {width: 100vw; height: 100vh; position: absolute; top: 0px; left: 0px; background-color: #ffffff; z-index: 1000;}

/*Layout*/


body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background-color: transparent;
}



.ie body {height: 0;}

.container {}
div#root {flex-grow: 1;}


footer {height: 50px; background: #3c587a; width: 100%; flex-grow: 0; padding: 5px 10px 6px 10px; border-bottom: 25px solid #e03944;}




/*Module*/

.banner {
  height: 50px;
  background: url(white-house.jpg) top left;
  background-size: contain;
  background-repeat: repeat;
  margin-bottom: 40px;
  display: none;
}
.jumbotron {
  background-color: rgba(255, 255, 255, 0.85);
  margin-bottom: 75px;
  padding: 20px 60px 20px 20px; margin-top: 40px;

  opacity: 0;

  animation-name: anim;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  overflow: hidden;


}

@keyframes anim {
  100% {
      opacity: 1;
  }
}


.btn-primary {background-color: #3c587a;}
/*.btn-primary:hover, .btn-primary:focus, .btn-primary:active {background-color: #9e2d34 !important;}*/

.jumbotron h1 {
  color: #3c587a;
  margin-bottom: 40px;
  text-shadow: 2px 2px white;
}

.jumbotron h1 span {display: block;}

.jumbotron label {
  color: #3c587a;
}
.jumbotron button {
  outline: none !important;
  border: none !important;
  /*margin-bottom: 40px;*/
  margin-top: 25px;
}


.jumbotron input::placeholder { /* Most modern browsers support this now. */
 color:    #999 !important;
}

.jumbotron .q-mark {
  color: #3c587a;
  font-size: 47px;
  padding-top: 14px;
  display: block;
}
.jumbotron .form-group#month .help-block {
  display: none;
}
.jumbotron .form-group#day .help-block {
  display: none;
}
.jumbotron .form-group#year .help-block {
  display: none;
}
.jumbotron .form-group.has-error#month .help-block {
  display: block;
}
.jumbotron .form-group.has-error#day .help-block {
  display: block;
}
.jumbotron .form-group.has-error#year .help-block {
  display: block;
}
.jumbotron #before-washington {
  display: none;
}
.jumbotron #in-the-future {
  display: none;
}

.jumbotron .info {background-color: rgba(0, 0, 0, 0.1); padding: 10px; margin-bottom: 10px; margin-top: 20px;}

.jumbotron .info p {font-size: 16px; color: #333; margin-bottom: 0; }

.modal .modal-content {

}
.modal .modal-header {
  background-color: #3c587a;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  position: relative;
  top: -2px;
  margin-bottom: 20px;
}

.modal .modal-header:after {
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -10px;
  left:0px;
  background: #e03944;
}

.modal .modal-header h4 {
  color: white;
}
.modal h1 {
  margin-bottom: 5px;
}
.modal h2 {
  font-size: 20px; margin: 0 0 10px 0;
}
.modal h3 {
  font-size: 18px; margin: 0 0 10px 0;
}
.modal p {
  margin: 0 0 40px 0;
}
.modal .quote {
  color: #666;
  font-style: italic;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal .close {
  color: white;
  opacity: .6;
}
.modal .close:hover {
  opacity: .8;
}

.modal .terms-list {
  margin: 0 0 20px 0;
  padding: 0;
}
.modal .terms-list li {
  list-style: none;
}
.modal .terms-list li span {
  font-weight: bold;
}
.modal .incumbent {
  font-style: italic;
}


.modal .terms-holder {
  height: 40px;
  padding-top: 20px;
  clear: both;
}
.modal .term-div {
  height: 20px;
  background-color: #3c587a;
  float: left;
  text-align: center;
}
.modal .term-div:nth-child(2n) {
  background-color: #0182ae;
}
.modal .term-div span {
  position: relative;
  top: -20px;
  font-weight: bold;
}
.modal .date-holder {
  height: 20px;
  position: relative;
  margin-bottom: 40px;
}
.modal .date-holder span.pointer {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 16px;
  width: 16px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #e03944;
}
.modal .date-holder span.pointer > span {
  display: block;
  font-size: 11px;
  left: auto;
  right: 12px;
  top: 2px;
  position: absolute;
  width: 120px;
  text-align: right;
  font-style: italic;
  color: #777;
}
.modal .date-holder span.pointer > span.right {
  text-align: right;
  right: auto;
  left: 12px;
  text-align: left;
}

.modal .modal-footer {
  background-color: #3c587a;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  position: relative;
  bottom: -2px;

}





footer .text {
  color: white;
  font-size: 12px !important;
  margin: 0;
  display: none;
}

footer .text:nth-child(1) {
  margin-bottom: 7px;
}


footer .text a {
  color: white;
  text-decoration: underline;
}




/*State*/

